import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { catchError, map, Observable, throwError } from 'rxjs';

import { ITechnician } from 'src/app/core/models';
import { IStoreState } from '../../../../core/models/store.interface';
import { EApprovalStatus, ResultUnit } from '../result-unit-card/result-unit.interface';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ResultUnitPreviewService extends ObservableStore<IStoreState> {
  private url = `${environment.apimBaseUrl}/technician/result-units`;
  private headers = new HttpHeaders().set('Ocp-Apim-Subscription-Key', environment.apimSubscriptionKey);

  constructor(private http: HttpClient) {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  public putLabResultApproval(labTechnician: ITechnician, labResultId: string, labResultIndex: number, approvalStatus: EApprovalStatus): Observable<{ resultUnit: ResultUnit; resultUnitIndex: number; message: string }> {
    return this.http.put<{ resultUnit: ResultUnit; resultUnitIndex: number; message: string }>(`${this.url}/${labTechnician.id}/approval/${labResultId}`, {
      resultUnitId: labResultId,
      resultUnitIndex: labResultIndex,
      labCenterAlias: labTechnician.labCenterAlias,
      status: approvalStatus,
      approver: {
        id: labTechnician?.id,
        givenName: labTechnician?.givenName,
        surname: labTechnician?.surname
      }
    }, { headers: this.headers }).pipe(
      map((response) => response),
      catchError(this.handleError('putLabResultApproval', this.url + '/?/approval/?'))
    );
  }

  public getReportMedia(requestId: string, mediaId: string): Observable<string> {
    return this.http
      .get<string>(
      `${this.url}/${requestId}?media_id=${mediaId}&action=report`,
      { headers: this.headers }
    )
      .pipe(
        map((reportMedia: string) => reportMedia),
        catchError(this.handleError('getReportMedia', this.url))
      );
  }

  private handleError(operation: string, url: string) {
    return (err: any) => {
      let errMsg = `ERROR in ${operation}() retrieving ${url}`;
      console.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        errMsg = `status: ${err.status}, ${err.statusText}`;
      }
      return throwError(() => new Error(errMsg));
    };
  }
}
