import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { catchError, map, Observable, of, throwError } from 'rxjs';

import { dbService } from 'src/app/core/services/db.service';

import { AppCoreActions, IStoreState, TechnicianActions } from 'src/app/core/models/store.interface';
import { ESubmissionMode, ESubmissionStatus, SubmissionDraft, SubmissionDraftKey } from 'src/app/features/result-unit-upload/submission-page/submission.interface';
import { ResultUnit } from '../../result-unit-list/components/result-unit-card/result-unit.interface';

import { IDbMedia } from 'src/app/core/services/db.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubmissionService extends ObservableStore<IStoreState> {
  static instance: SubmissionService;
  private url = `${environment.apimBaseUrl}/technician/result-units`;
  private headers = new HttpHeaders().set('Ocp-Apim-Subscription-Key', environment.apimSubscriptionKey);

  constructor(private http: HttpClient) {
    super({ trackStateHistory: true, logStateChanges: false });
    SubmissionService.instance = this;
  }

  public addLabResult(requestId: string, labResult: ResultUnit): Observable<any> {
    return this.http.put<any>(`${this.url}/${requestId}/add`, labResult, { headers: this.headers }).pipe(
      map(response => response),
      catchError(this.handleError('addLabResult', `${this.url}/add`))
    );
  }

  public updateLabResult(requestId: string, labResult: ResultUnit): Observable<any> {
    return this.http.put<any>(`${this.url}/${requestId}/update`, labResult, { headers: this.headers }).pipe(
      map(response => response),
      catchError(this.handleError('updateLabResult', `${this.url}/update`))
    );
  }

  getSubmissionDrafts(): Observable<Record<SubmissionDraftKey, SubmissionDraft>> {
    const state = this.getState();
    if (state && state.submissionDrafts) {
      return of(state.submissionDrafts);
    }
  }

  getSubmissionMode(): Observable<ESubmissionMode> {
    const state = this.getState();
    if (state && state.submissionMode) {
      return of(state.submissionMode);
    }
  }

  getCurrentLabResult(): Observable<ResultUnit> {
    const state = this.getState();
    if (state) {
      return of(state.currentLabResult);
    }
  }

  setSubmissionMode(mode: ESubmissionMode): void {
    this.setState({ submissionMode: mode }, TechnicianActions.setSubmissionMode);
  }

  addSumissionDraft(submissionDraft: SubmissionDraft): void {
    let submissionDrafts = this.getState()?.submissionDrafts ?? {};
    submissionDrafts[submissionDraft?.submissionId] = submissionDraft;
    this.setState({ submissionDrafts }, TechnicianActions.setCurrentSubmissionDraft);
  }

  async updateSubmissionDraft(submissionDraftId: string, completed = false): Promise<void> {
    let submissionDrafts = this.getState()?.submissionDrafts ?? {};
    if (!completed) {
      if (submissionDrafts[submissionDraftId].submissionStatus === ESubmissionStatus.PROGRESS) {
        const draftReport: IDbMedia = await dbService.retrieveSubmissionDraftMedia(submissionDraftId);
        submissionDrafts[submissionDraftId].submissionStatus = draftReport && draftReport.submissionId ? ESubmissionStatus.FAILED : ESubmissionStatus.RE_UPLOAD;
        this.setState(
          { submissionDrafts },
          draftReport && draftReport.submissionId ? AppCoreActions.setSubmissionDraftFailedStatus : AppCoreActions.setSubmissionDraftReuploadStatus
        );
      }

    } else {
      if (submissionDrafts[submissionDraftId]) {
        let submissionDraft = new SubmissionDraft(submissionDrafts[submissionDraftId].resultUnit);
        submissionDraft.submissionStatus = ESubmissionStatus.SUCCESS;
        submissionDraft.autoRemove();
        submissionDrafts[submissionDraftId] = submissionDraft;
        await dbService.deleteSubmissionDraftMedia(submissionDraftId);
        this.setState(
          { submissionDrafts },
          AppCoreActions.setSubmissionDraftSuccessStatus
        );
      }
    }
  }

  removeSubmissionDraft(submissionDraftId: string): void {
    let submissionDrafts = this.getState()?.submissionDrafts ?? {};
    delete submissionDrafts[submissionDraftId];
    this.setState(
      { submissionDrafts },
      AppCoreActions.removeSuccessfullSubmissionDraft
    );
  }

  async deleteSubmissionDraft(submissionDraftId: string, cancelled = false): Promise<void> {
    let submissionDrafts = this.getState()?.submissionDrafts ?? {};
    delete submissionDrafts[submissionDraftId];
    await dbService.deleteSubmissionDraftMedia(submissionDraftId);
    this.setState(
      { submissionDrafts },
      cancelled ? TechnicianActions.cancelSubmissionDraft : TechnicianActions.deleteSubmissionDraft
    );
  }

  private handleError(operation: string, url: string) {
    return (err: any) => {
      let errMsg = `ERROR in ${operation}() => url: ${url}`;
      console.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        errMsg = `status: ${err.status}, ${err.statusText}`;
      }
      return throwError(() => new Error(errMsg));
    };
  }
}
