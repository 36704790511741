export const environment = {
  apimBaseUrl: 'https://dev-apim-akashinga.azure-api.net',
  apimSubscriptionKey: '4a28cd794ca64aeebc77121ce8b75de9',
  authority: 'https://zovusolutionslabcenter.b2clogin.com/zovusolutionslabcenter.onmicrosoft.com/B2C_1_DipoKnawou_SignIn',
  clientId: 'ccaee847-4273-408c-b7c3-cbe6ffa5b7da',
  insightConnexionString: 'InstrumentationKey=ec84f3d8-084c-4260-b926-e3bcee595957;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=23dd0eaf-6406-4dc0-b014-edfc6267ca46',
  knownAuthorities: ['zovusolutionslabcenter.b2clogin.com'],
  nodeEnv: 'dev',
  postLogoutRedirectUri: `${location.origin}/signin`,
  production: false,
  redirectUri: `${location.origin}/home`,
  tenantId: 'd8500272-4036-473c-857f-e0b4284211d3',
  webNodeServer: {
    socketUrl: 'https://zovusolutionsnotifications.com',
    socketPorts: {
      'INH-LOME': 46625,
      'BIASA-LOME': 45555,
      'PERFECT_LABO-LOME': 44530,
      'ZL-AYENGRE': 44444,
    },
  },
  ablySubscriptionKey: '8_efEA.hCIG5A:dQT9dBWy0922BuI-_-pQcHPldik4XATDxq_2Zd9ww8w',
  appName: 'Technician Portal Web'
};
