import { Subscription, timer } from 'rxjs';

import { SubmissionService } from 'src/app/features/result-unit-upload/submission-page/submission-page.service';

import { ITechnician } from 'src/app/core/models';
import { ResultUnit } from 'src/app/features/result-unit-list/components/result-unit-card/result-unit.interface';

export enum EMetaType {
  PDF = 'pdf',
  FORM = 'form',
  IMAGE = 'image',
}

export enum ESubmissionStatus {
  SUCCESS = 'success',
  PROGRESS = 'progress',
  FAILED = 'failed',
  RE_UPLOAD = 're_upload'
}

export enum ESubmissionMode {
  NEW_SUBMISSON = 'new',
  EDIT_SUBMISSON = 'edit',
  RE_UPLOAD_SUBMISSON = 're_upload'
}

export type SubmissionDraftKey = keyof { [submissionId: string]: SubmissionDraft };

export class SubmissionDraft {
  submissionId: string;
  submissionStatus: ESubmissionStatus;
  submissionDateTime: number;
  resultUnit: ResultUnit;

  private failedTimer = timer(300000);
  private removeTimer = timer(10000);

  constructor(data?: ResultUnit) {
    this.resultUnit = data;
    this.submissionId = data?.submission?.id;
    this.submissionStatus = ESubmissionStatus.PROGRESS;
    this.submissionDateTime = data?.submission?.submissionDateTime;
  }

  startTimer() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const timerSub: Subscription = this.failedTimer.subscribe((output) => {
      SubmissionService.instance.updateSubmissionDraft(this.submissionId);
      timerSub?.unsubscribe();
    });
  }

  autoRemove() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const timerSub: Subscription = this.removeTimer.subscribe((output) => {
      SubmissionService.instance.removeSubmissionDraft(this.submissionId);
      timerSub?.unsubscribe();
    });
  }
}

export class Submission {
  id: string;
  portal: string;
  meta: {
    type: EMetaType;
    editVersion: number;
  };
  category: {
    labDepartment: string;
    studyName: string;
    protected: boolean;
  };
  submissionDateTime: number;
  updateBy: ITechnician;
  media: {
    mediaId: string;
    fileName: string;
    rawFile: string;
    fileExtension: string;
    fileContentType: string;
    uploadDateTime: number;
    pagesCount: number;
  };
  comments: string;

  constructor() { }
}
