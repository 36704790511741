import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { ToasterService } from 'src/app/core/components/toaster/toaster.service';
import { AuthenticationService, LabCenterService } from 'src/app/core/services';
import { dbService } from 'src/app/core/services/db.service';
import { TranslocoHttpLoader } from 'src/app/transloco/transloco.service';
import { ModalService } from '../../../shared/modal/modal.service';
import { ResultUnitListService } from '../../result-unit-list/result-unit-list-page/result-unit-list.service';
import { PatientSearchService } from '../patient-search-page/patient-search.service';
import { SubmissionService } from './submission-page.service';

import { FilePreviewModalComponent } from 'src/app/features/result-unit-upload/components/file-preview-modal/file-preview-modal.component';
import { IReport } from 'src/app/features/result-unit-upload/components/upload-card/upload-card.component';
import { ModalComponent } from '../../../shared/modal/modal.component';

import {
  EToastStatus,
  IToast,
} from 'src/app/core/components/toaster/toast-tile/toast-tile.interface';
import { ILabCenter, ITechnician } from 'src/app/core/models';
import { IHealthEntity } from 'src/app/core/models/health-entity.interface';
import { HealthEntitieservice } from 'src/app/core/services/health-entities.service';
import {
  EMetaType,
  ESubmissionMode,
  SubmissionDraft,
} from 'src/app/features/result-unit-upload/submission-page/submission.interface';
import { environment } from 'src/environments/environment';
import { ModalContent, ModalType } from '../../../shared/modal/modal.interface';
import { IPatient } from '../../result-unit-list/components/patient-card/patient.interface';
import { ResultUnit } from '../../result-unit-list/components/result-unit-card/result-unit.interface';

@Component({
  selector: 'ztp-submission-page',
  templateUrl: './submission-page.component.html',
  styleUrls: ['./submission-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmissionPageComponent
implements OnInit, OnDestroy, AfterViewInit
{
  get comments(): AbstractControl {
    return this.submissionForm.get('comments');
  }

  get uploadReport(): AbstractControl {
    return this.submissionForm.get('uploadReport');
  }

  get labTestName(): AbstractControl {
    return this.submissionForm.get('labTestName');
  }

  get labDepartment(): AbstractControl {
    return this.submissionForm.get('labDepartment');
  }

  get prescriber(): AbstractControl {
    return this.submissionForm.get('prescriber');
  }

  get clinic(): AbstractControl {
    return this.submissionForm.get('clinic');
  }

  private uploadPageNavigationTimeOut: any;

  private modalContent: ModalContent;

  private cancelModalContent: ModalContent;

  private deleteReportModalContent: ModalContent;

  private modalSubscription: Subscription;

  private authSub: Subscription;

  private patientSearchSub: Subscription;

  private labCenterSub: Subscription;

  private submissionSub: Subscription;

  private toasterSub: Subscription;

  currentPatient: IPatient;

  currentLabTechnician: ITechnician;

  currentLabCenter: ILabCenter;

  currentPrescriber: IHealthEntity['provider'];

  currentClinic: IHealthEntity['clinic'];

  currentLabResult: ResultUnit;

  submissionMode: ESubmissionMode;

  submissionDraft: SubmissionDraft;

  submissionForm: FormGroup;

  fileUploader: FileUploader;

  currentSubmissionReport: IReport;

  currentSubmissionId: string;

  currentSubmissionEditVersion: number;

  currentSubmissionDateTime: number;

  canEditLabResult = false;

  isLabTestProtected: boolean;

  isResultUnitReportDeleted = false;

  isReportFilesInvalid: boolean;

  isFilesCompressInProgress: boolean;

  isSubmiting = false;

  dataLiveLoadingCheck = {
    labTestName: {
      hasData: false,
      hasFocus: false,
    },
    uploadReport: {
      hasData: false,
      hasFocus: false,
    },
    comments: {
      hasData: false,
      hasFocus: false,
    },
    prescriber: {
      hasData: false,
      hasFocus: false,
    },
    clinic: {
      hasData: false,
      hasFocus: false,
    },
  };

  providersList: Observable<IHealthEntity['provider'][]>;

  clinicsList: Observable<IHealthEntity['clinic'][]>;

  constructor(
    private modalService: ModalService,
    private authService: AuthenticationService,
    private patientSearchService: PatientSearchService,
    private labCenterService: LabCenterService,
    private submissionService: SubmissionService,
    private resultListService: ResultUnitListService,
    private translocoService: TranslocoHttpLoader,
    private router: Router,
    private toasterService: ToasterService,
    private readonly _location: Location,
    private readonly healthEntitiesService: HealthEntitieservice
  ) {
    this.submissionForm = new FormGroup({
      comments: new FormControl(''),
      uploadReport: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
      ]),
      labTestName: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
      isLabTestProtected: new FormControl(null, [
        Validators.required,
      ]),
      labDepartment: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
      prescriber: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
      clinic: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
    });
  }

  ngOnInit(): void {
    this.submissionSub = this.submissionService
      .getSubmissionMode()
      .subscribe((submissionMode) => {
        this.submissionMode = submissionMode;
      });
    this.submissionSub = this.submissionService
      .getCurrentLabResult()
      .subscribe((currentLabResult) => {
        this.currentLabResult = currentLabResult;
        if (this.submissionMode !== ESubmissionMode.NEW_SUBMISSON) {
          this.labTestName.setValue(
            currentLabResult?.submission.category.studyName
          );
          if (currentLabResult?.healthcareProvider?.clinic) {
            this.clinic.setValue(
              currentLabResult?.healthcareProvider?.clinic.name
            );
            this.currentClinic = currentLabResult?.healthcareProvider?.clinic;
          }

          if (currentLabResult?.healthcareProvider?.provider) {
            this.prescriber.setValue(
              `${currentLabResult?.healthcareProvider?.provider.title} ${currentLabResult?.healthcareProvider?.provider.givenName} ${currentLabResult?.healthcareProvider?.provider.surname}`
            );
            this.currentPrescriber = currentLabResult?.healthcareProvider?.provider;
          }
        }
      });
    this.loadTranslation();
    this.modalSubscription = this.modalService.modalActions
      .pipe(filter((data) => data !== null))
      .subscribe((action) => {
        this.runSubmitModalActionCallback(action);
      });
  }

  ngAfterViewInit() {
    this.submissionForm.valueChanges.subscribe((val) => {
      this.dataLiveLoadingCheck.labTestName.hasData = !!val?.labTestName;
      this.dataLiveLoadingCheck.comments.hasData = !!val?.comments;
      this.dataLiveLoadingCheck.prescriber.hasData = !!val?.prescriber;
      this.dataLiveLoadingCheck.clinic.hasData = !!val?.clinic;
    });

    this.patientSearchSub = this.patientSearchService
      .getCurrentPatient()
      .subscribe((currentPatient) => (this.currentPatient = currentPatient));

    this.authSub = this.authService
      .getCurrentLabTechnician()
      .subscribe((currentLabTechnician) => {
        this.currentLabTechnician = currentLabTechnician;
        this.providersList = this.healthEntitiesService.getProvider(
          currentLabTechnician.countryCodeISO2
        );
        this.clinicsList = this.healthEntitiesService.getClinic(
          currentLabTechnician.countryCodeISO2
        );
      });

    this.labCenterSub = this.labCenterService
      .getCurrentLabCenter()
      .subscribe(
        (currentLabCenter) => (this.currentLabCenter = currentLabCenter)
      );

    this.loadSubmissionData();
  }

  public loadSubmissionData(): void {
    if (this.submissionMode !== ESubmissionMode.NEW_SUBMISSON) {
      this.labDepartment.setValue(
        this.currentLabResult?.submission.category.labDepartment
      );
      this.comments.setValue(this.currentLabResult?.submission.comments);
    }
    if (this.submissionMode === ESubmissionMode.RE_UPLOAD_SUBMISSON) {
      this.submissionService.deleteSubmissionDraft(
        this.currentLabResult?.submission.id
      );
    }
    if (this.submissionMode === ESubmissionMode.EDIT_SUBMISSON) {
      //download document and set current report
    }
  }

  public loadTranslation(): void {
    this.modalSubscription = this.translocoService
      .getTranslation()
      .subscribe((data) => {
        this.modalContent = {
          message: data['modal_submission']['message'],
          title: 'Confirmation',
          route: '',
          actions: {
            primaryAction: data['modal_submission']['primary_action'],
            secondaryAction: data['modal_submission']['secondary_action'],
          },
          type: ModalType.CONFIRMATION,
        };
        this.deleteReportModalContent = {
          title: 'Confirmation',
          type: ModalType.WARNING,
          actions: {
            primaryAction: data['modal_report_deletion']['primary_action'],
            secondaryAction: data['modal_report_deletion']['secondary_action'],
          },
          message: data['modal_report_deletion']['message'],
          route: '',
        };
        this.cancelModalContent = {
          title: 'Confirmation',
          type: ModalType.WARNING,
          actions: {
            primaryAction: data['modal_cancel']['primary_action'],
            secondaryAction: data['modal_cancel']['secondary_action'],
          },
          message: data['modal_cancel']['message'],
          route: '',
        };
      });
  }

  private runSubmitModalActionCallback(action: string): void {
    switch (action) {
    case 'Continue':
    case 'Continuer':
      this.submitLabResult();
      break;

    case 'Cancel':
    case 'Annuler':
    case 'No':
    case 'Non':
      this.abortSubmission();
      break;

    case 'Delete':
    case 'Effacer':
      this.removeResultUnitReports();
      break;

    case 'Yes':
    case 'Oui':
      this.cancelSubmission();
      break;
    }
  }

  private async initLabResultCreation(id?: string): Promise<void> {
    let resultUnit = new ResultUnit();
    resultUnit.creationDateTime = this.currentSubmissionDateTime;
    resultUnit.id = id ?? uuidV4();
    resultUnit.idAlias = 0;
    resultUnit.patient = this.currentPatient;
    resultUnit.technician = this.currentLabTechnician;
    resultUnit.labCenter = this.currentLabCenter;
    resultUnit.submission = {
      id: this.currentSubmissionId,
      portal : `${environment.appName}`,
      meta: {
        type: this.getReportType(this.currentSubmissionReport.fileExtension),
        editVersion: this.currentSubmissionEditVersion,
      },
      category: {
        labDepartment: this.labDepartment.value,
        studyName: this.labTestName.value,
        protected: this.isLabTestProtected
      },
      submissionDateTime: this.currentSubmissionDateTime,
      updateBy: this.currentLabTechnician,
      media: {
        mediaId: `${this.currentLabCenter.alias.toLowerCase()}_${this.getNameAsId(
          this.currentPatient.surname,
          this.currentPatient.givenName
        )}_${this.currentSubmissionId}_${this.currentSubmissionEditVersion}`,
        fileName: `${this.currentSubmissionId}.${this.currentSubmissionReport.fileExtension}`,
        rawFile: '',
        fileExtension: this.currentSubmissionReport.fileExtension,
        fileContentType: this.currentSubmissionReport.fileContentType,
        uploadDateTime: this.currentSubmissionDateTime,
        pagesCount: this.currentSubmissionReport.pagesCount,
      },
      comments: this.comments.value,
    };
    resultUnit.healthcareProvider = {
      provider: this.currentPrescriber,
      clinic: this.currentClinic,
    };

    delete resultUnit.labCenter['_id'];
    delete resultUnit.technician['countryCodeISO2'];
    delete resultUnit.submission.updateBy['countryCodeISO2'];
    let submissionDraft = new SubmissionDraft(resultUnit);
    submissionDraft.startTimer();
    this.submissionService.addSumissionDraft(submissionDraft);
    resultUnit.submission.media.rawFile = this.currentSubmissionReport
      .fileItem as string;
    await dbService.insertSubmissionDraftMedia(
      resultUnit.submission.id,
      resultUnit.submission.media.rawFile
    );
    this.initToast();
    this.router.navigate(['/upload']).then(() => {
      this.submissionService
        .addLabResult(this.currentLabTechnician.id, resultUnit)
        .subscribe();
      this.isSubmiting = false;
    });
  }

  public submitLabResult(): void {
    this.isSubmiting = true;
    this.currentSubmissionDateTime = Date.now();

    switch (this.submissionMode) {
    case ESubmissionMode.NEW_SUBMISSON:
      this.currentSubmissionId = uuidV4();
      this.currentSubmissionEditVersion = 0;
      this.createLabResult();
      break;
    case ESubmissionMode.EDIT_SUBMISSON:
      this.currentSubmissionId = this.currentLabResult.submission.id;
      this.currentSubmissionEditVersion =
            this.currentLabResult.submission.meta.editVersion + 1;
      this.editLabResult();
      break;
    case ESubmissionMode.RE_UPLOAD_SUBMISSON:
      this.currentSubmissionId = uuidV4();
      this.currentSubmissionEditVersion = 0;
      this.reCreateLabResult();
      break;

    default:
      break;
    }
  }

  public createLabResult() {
    this.initLabResultCreation();
  }

  public async reCreateLabResult(): Promise<void> {
    const id = this.currentLabResult.id;
    this.initLabResultCreation(id);
  }

  public async editLabResult(): Promise<void> {
    let resultUnit = new ResultUnit();
    resultUnit.creationDateTime = this.currentSubmissionDateTime;
    resultUnit.id = this.currentLabResult.id;
    resultUnit.idAlias = this.currentLabResult.idAlias;
    resultUnit.patient = this.currentPatient;
    resultUnit.technician = this.currentLabResult.technician;
    resultUnit.labCenter = this.currentLabCenter;
    resultUnit.submission = {
      id: this.currentSubmissionId,
      portal: `${environment.appName}`,
      meta: {
        type:
          this.isResultUnitReportDeleted &&
          this.currentSubmissionReport?.fileItem
            ? this.getReportType(this.currentSubmissionReport.fileExtension)
            : this.currentLabResult.submission.meta.type,
        editVersion: this.currentSubmissionEditVersion,
      },
      category: {
        labDepartment: this.labDepartment.value,
        studyName: this.labTestName.value,
        protected: this.isLabTestProtected
      },
      submissionDateTime: this.currentSubmissionDateTime,
      updateBy: this.currentLabTechnician,
      media:
        this.isResultUnitReportDeleted && this.currentSubmissionReport?.fileItem
          ? {
            mediaId: `${this.currentLabCenter.alias.toLowerCase()}_${this.getNameAsId(
              this.currentPatient.surname,
              this.currentPatient.givenName
            )}_${this.currentSubmissionId}_${
              this.currentSubmissionEditVersion
            }`,
            fileName: `${this.currentSubmissionId}.${this.currentSubmissionReport.fileExtension}`,
            rawFile: '',
            fileExtension: this.currentSubmissionReport.fileExtension,
            fileContentType: this.currentSubmissionReport.fileContentType,
            uploadDateTime: this.currentSubmissionDateTime,
            pagesCount: this.currentSubmissionReport.pagesCount,
          }
          : { ...this.currentLabResult.submission.media, rawFile: '' },
      comments: this.comments.value,
    };
    resultUnit.healthcareProvider = {
      provider: this.currentPrescriber,
      clinic: this.currentClinic,
    };
    delete resultUnit.labCenter['_id'];
    delete resultUnit.technician['countryCodeISO2'];
    delete resultUnit.submission.updateBy['countryCodeISO2'];
    let submissionDraft = new SubmissionDraft(resultUnit);
    submissionDraft.startTimer();
    this.submissionService.addSumissionDraft(submissionDraft);
    if (
      this.isResultUnitReportDeleted &&
      this.currentSubmissionReport?.fileItem
    ) {
      resultUnit.submission.media.rawFile = this.currentSubmissionReport
        .fileItem as string;
    }
    await dbService.insertSubmissionDraftMedia(
      resultUnit.submission.id,
      resultUnit.submission.media.rawFile
    );
    this.initToast();
    this.router.navigate(['/home']).then(() => {
      this.submissionService
        .updateLabResult(this.currentLabTechnician.id, resultUnit)
        .subscribe();
      this.isSubmiting = false;
    });
  }

  private initToast(): void {
    let toastData: IToast = {
      status: EToastStatus.IN_PROGRESS,
      patient: {
        givenName: this.currentPatient.givenName,
        surname: this.currentPatient.surname,
        zovuId: this.currentPatient.zovuId,
      },
      studyName: this.labTestName.value,
      media: this.getMediaData(),
    };
    this.toasterService.addToast(toastData);
  }

  private getMediaData(): { type: string; pagesCount: number } {
    if (this.submissionMode !== ESubmissionMode.EDIT_SUBMISSON) {
      return {
        type: this.currentSubmissionReport.fileExtension,
        pagesCount: this.currentSubmissionReport.pagesCount
      };
    }

    if (this.isResultUnitReportDeleted && this.currentSubmissionReport?.fileItem) {
      return {
        type: this.currentSubmissionReport.fileExtension,
        pagesCount: this.currentSubmissionReport.pagesCount
      };
    }

    return {
      type: this.currentLabResult.submission.media.fileExtension,
      pagesCount: this.currentLabResult.submission.media.pagesCount
    };
  }

  public abortSubmission(): void {
    console.log('Submission aborted');
  }

  public cancelSubmission(): void {
    this.resultListService.clearCurrentLabResult();
    setTimeout(() => {
      this._location.back();
    }, 500);
  }

  public setTestName(selectValue: {
    labTestName: string;
    labDepartment: string;
    isLabTestProtected: boolean;
  }): void {
    this.submissionForm.controls['labTestName'].setValue(
      selectValue?.labTestName
    );
    this.submissionForm.controls['labDepartment'].setValue(
      selectValue?.labDepartment
    );
    this.isLabTestProtected = selectValue?.isLabTestProtected;
  }

  public setPrescriber(selectValue: any): void {
    delete selectValue.memberNumber;
    delete selectValue.name;
    delete selectValue.id;
    this.currentPrescriber = selectValue;
    this.submissionForm.controls['prescriber'].setValue(selectValue.name);
  }

  public setClinic(selectValue: any): void {
    selectValue.id = selectValue.guid;
    delete selectValue.guid;
    this.currentClinic = selectValue;
    this.submissionForm.controls['clinic'].setValue(selectValue.name);
  }

  public setStudyListBoxOpenStatus(boxOpenStatus: boolean): void {
    this.dataLiveLoadingCheck.labTestName.hasFocus = boxOpenStatus;
  }

  public setPrescriberBoxOpenStatus(boxOpenStatus: boolean): void {
    this.dataLiveLoadingCheck.prescriber.hasFocus = boxOpenStatus;
  }

  public setClinicBoxOpenStatus(boxOpenStatus: boolean): void {
    this.dataLiveLoadingCheck.clinic.hasFocus = boxOpenStatus;
  }

  public setFileBoxOpenStatus(boxOpenStatus: boolean): void {
    this.dataLiveLoadingCheck.uploadReport.hasFocus = boxOpenStatus;
  }

  public setCommentEditStatus(commentBoxFocusStatus: boolean): void {
    this.dataLiveLoadingCheck.comments.hasFocus = commentBoxFocusStatus;
  }

  public setReportValidity(error: boolean): void {
    this.isReportFilesInvalid = error;
  }

  public setFileCompressStatus(isCompressing: boolean) {
    this.isFilesCompressInProgress = isCompressing;
  }

  public saveReport(report: IReport) {
    this.currentSubmissionReport = report;
  }

  public saveFileQueue(uploader: FileUploader): void {
    this.fileUploader = uploader;
    // this.dataLiveLoadingCheck.uploadReport.hasData = uploader?.queue?.length > 0;
  }

  public submitModal(): void {
    const inputs = {
      modalContent: this.modalContent,
    };
    this.modalService.init(ModalComponent, inputs);
  }

  public cancelModal(): void {
    if (
      this.submissionMode === ESubmissionMode.EDIT_SUBMISSON &&
      this.labTestName.value ===
        this.currentLabResult.submission.category.studyName &&
      !this.isResultUnitReportDeleted &&
      !this.submissionForm.dirty
    ) {
      this.cancelSubmission();
    } else {
      const inputs = {
        modalContent: this.cancelModalContent,
      };
      this.modalService.init(ModalComponent, inputs);
    }
  }

  public showDeletionConfirmationModal(): void {
    const inputs = {
      modalContent: this.deleteReportModalContent,
    };
    this.modalService.init(ModalComponent, inputs);
  }

  openPreview(item: IReport) {
    item.fileName = `${this.currentSubmissionId}.${this.currentSubmissionReport.fileExtension}`;
    this.modalService.init(FilePreviewModalComponent, {
      fileItem: item,
      isReport: true,
    });
  }

  reportPagesCounterMessage(n: number) {
    if (String(n).length == 1) {
      return `0${n} page${n > 1 ? 's' : ''}`;
    } else {
      return `${n} pages`;
    }
  }

  public getReportType(type: string): EMetaType {
    return {
      pdf: EMetaType.PDF,
      jpg: EMetaType.IMAGE,
      jpeg: EMetaType.IMAGE,
      png: EMetaType.IMAGE,
      form: EMetaType.FORM,
    }[type];
  }

  public getNameAsId(surname: string, givenName: string): string {
    return `${surname}-${givenName}`;
    // return `${surname} ${givenName}`.trim().match(/\b(\w+)\b/g).join('-');
  }

  private removeResultUnitReports(): void {
    //TODO: look at this
    this.canEditLabResult = true;
    this.isResultUnitReportDeleted = true;
  }

  ngOnDestroy(): void {
    this.modalSubscription?.unsubscribe();
    this.authSub?.unsubscribe();
    this.patientSearchSub?.unsubscribe();
    this.labCenterSub?.unsubscribe();
    this.submissionSub?.unsubscribe();
    this.toasterSub?.unsubscribe();
    clearTimeout(this.uploadPageNavigationTimeOut);
  }
}

//TODO: return back to the list with focused context after edit mode
